import React from 'react'
import BookAppointment from '../components/appointment/Appointment'

const Appointment = () => {
  return (
    <div>
      <BookAppointment />
    </div>
  )
}

export default Appointment
