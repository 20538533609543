import React from 'react'
import { Link } from 'react-router-dom'
import './PageNotFound.css'
import P404 from '../../assets/images/404.png'


const PageNotFound = () => {
  return (
    <div className='pagenotfound'>
        <div className="left-section">
          <h1><span>404!</span><br/> Looks like you entered the wrong ward</h1>
          <h2>Back to <Link to='/'> Home Page</Link></h2>
        </div>        
        <img src={P404} alt="Not Found" />
    </div>
  )
}

export default PageNotFound