import React from 'react'
import PageNotFound from '../components/pagenotfound/PageNotFound'

const PageNotFount = () => {
  return (
    <div>
        <PageNotFound />
    </div>
  )
}

export default PageNotFount