import React from 'react'
import './Heartspin.css'
import Heart from '../../assets/images/cross1.png'

const Heartspin = () => {
  return (
    <div className='heartspin'>
        <img src={Heart} alt="Heart" />
    </div>
  )
}

export default Heartspin