import React from 'react'
import './SectionContent.css'

const TopSectionContent = () => {
  return (
    <div className='top-section'>
        <h2>Have you checked your Mental Health today?</h2>
        <p>Maintaining a healthy mental state is important for overall well-being and can have a positive impact on various aspects of our lives, including work, relationships, and physical health. Here are some tips for maintaining a healthy mental state:
          <li>Practice self-care</li>
          <li>Connect with others</li>
          <li>Seek professional help</li>
          <li>Take breaks</li>
          <li>Avoid unhealthy coping mechanisms</li>
          <li>Talk to a doctor</li>
              
        Remember, taking care of your mental health is just as important as taking care of your physical health. By incorporating healthy habits into your daily routine, you can improve your mental well-being and overall quality of life.</p>
    </div>
  )
}

export default TopSectionContent