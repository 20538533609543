import React from 'react'
import BlogPost from '../components/blogpost/BlogPost'
import BlogDetails from '../components/blogpost/BlogDetails'

const Blog = () => {
  return (
    <div className='blog-container'>
      <BlogPost />
    </div>
  )
}

export default Blog
