import React from 'react'
import LogoImage from '../../assets/images/final_logo.png'
import './Logo.css'

const Logo = () => {
  return (
    <div className='logo-self'>
        <img src={LogoImage} alt="St Mary Medical Center" />
    </div>
  )
}

export default Logo