import React from 'react'
import './Mission.css'

const Mission = () => {
    const mission="Our mission is to provide high-quality, evidence-based mental health care to individuals through online telehealth consultations. We are committed to fostering a safe, non-judgmental, and supportive environment where individuals can explore their concerns, receive personalized treatment, and achieve their goals. We strive to empower our clients with the knowledge and skills to take control of their mental health and live their best lives."
  return (
    <div className='Mission'>
        <h2>Our Mission</h2>
        <blockquote>{mission}</blockquote>
    </div>
  )
}

export default Mission