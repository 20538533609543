import React from 'react'
import './UpcomingEvents.css'

const UpcomingEvents = () => {
    
  return (

    <div className='main-event-container hide-event-comp' id='upcoming-event'>
        <div className="left-content">
            <h1>Upcoming Event</h1>            
            <p>Title of event</p>
        </div>
        <div className="right-content">
            <p>Time before event</p>
            <section className='time-left'>
                <span> 
                    <h1>00</h1> <h4>Days</h4>
                </span>
                <span> 
                    <h1>00</h1> <h4>Hours</h4>
                </span>
                <span> 
                    <h1>00</h1> <h4>Mins</h4>
                </span>
            </section>
        </div>
        
    </div>
  )
}

export default UpcomingEvents