import React from 'react'
import './Section.css'
import Anxiety from '../../assets/images/anxiety.png'

const Section = () => {
    
  return (
    <div className="section-1">
      <div className="section-left">
        <div className="section-1-left">
          <h1>You are welcome</h1>
          <div className="left-top-section-text">
            <p><span>Welcome to our Psychiatric Telehealth Services platform.</span> We are committed to providing quality mental health consultaion to individuals in the comfort and privacy of their own homes. Our team of experienced and compassionate psychiatrists and therapists offer a range of services, including consultation on medication management, psychotherapy, and supportive counseling. With our easy-to-use telehealth platform, you can connect with us from anywhere, at our open hours, using your computer, tablet, or smartphone. We understand that seeking mental health care can be challenging, and we are here to support you every step of the way. Please explore our website to learn more about our services and how we can help you achieve your mental health goals and work with you towards improvement.</p>
            <div className="mid-content">
              <img src={Anxiety} alt="" />
            </div>
          </div>          
        </div>  
      </div>
      
        
    </div>
  )
}

export default Section
