import React from 'react'
import './Mission.css'

const Vision = () => {
    const Vision="Our vision is to make mental health care accessible, convenient, and compassionate through online telehealth consultations. We believe everyone deserves the opportunity to take control of their mental health and live a fulfilling life."
  return (
    <div className='Vision'>
        <h2>Our Vision</h2>
        <blockquote>{Vision}</blockquote>
    </div>
  )
}

export default Vision