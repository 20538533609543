import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {

  const heroText = "Your health, our priority";
  const heroSub = "“I believe that the greatest gift you can give your family and the world is a healthy you.”"
  
  return (
    <div className='header-container'>
      <div className="bottom-nav">
        <div className="hero-image">
        </div>
        <div className="hero-text">
          <h1>{heroText}</h1>
          <span className='text-text'><p className='hero-sub'>{heroSub} <br /><small>–Joyce Meyer</small></p></span>
          <Link to="/appointment"><button className='btn-appointment'>Book an Appointment</button></Link>
          
        </div>
        
      </div>

    </div>
  )
}

export default Header
