import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../../pages/Home'
import About from '../../pages/About'
import Blog from '../../pages/Blog'
import Appointment from '../../pages/Appointment'
import PageNotFound from '../../pages/PageNotFound'
import BlogDetails from '../../components/blogpost/BlogDetails'
import Login from '../../components/login/Login'
import Dashboard from '../../components/login/Dashboard'


export const RoutesConfig = () => {
  return (
    <div>
        <Routes>
            <Route 
                exact 
                path='/' 
                element={<Home />}
            />
            <Route 
                path='/about' 
                element={<About />}
            />
            <Route 
                path='/blog' 
                element={<Blog />}
            />
            <Route 
                path='/appointment' 
                element={<Appointment />}
            />  
            <Route 
                path='/post/:id' 
                element={<BlogDetails />} 
            />
             <Route 
                path='/dashboard/*' 
                element={<Dashboard />} 
            />                                      
            <Route 
                path='*' 
                element={<PageNotFound />} 
            />             
      </Routes>
    </div>
  )
}
