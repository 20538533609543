import React from 'react'
import BlogSidebar from '../serviceblog/BlogSidebar'
import './BlogPost.css'
import ServiceBlog from '../serviceblog/ServiceBlog'

const BlogPost = () => {
  return (
    <div className="blog-container">
      <div className="blog-det">
        <ServiceBlog />
      </div>
      <div className="blog-sidebar">
        <BlogSidebar />
      </div>
    </div>
  )
}

export default BlogPost