import React from 'react'
import './AboutPage.css'
import Vision from '../mission/Vision'
import Mission from '../mission/Mission'
import OurVision from '../../assets/images/her0-banner.jpg'
import DoctorImg from '../../assets/images/dedoc1.jpg'
import TeamSection from '../teamSection/TeamSection'

const AboutPage = () => {

  const AboutDoc = `Dr. Oluwatoyin Adekeye, DNP, PMHNP-BC, FNP-C, is a dual board-certified Psychiatric Mental Health Nurse Practitioner (PMHNP) and a Family Nurse Practitioner (FNP). She received her Post-Doctoral Certificate in Psychiatric Mental Health Nurse Practitioner from Brandman University. She also received her Doctor of Nursing Practice and Master of Science in Family Nurse Practitioner from Walden University. She has double Bachelor of Science in Nursing and Biology from Howard University. Dr. Adekeye has worked in a variety of settings including inpatient and outpatient psychiatry, emergency medicine, internal medicine and pain management. Dr. Adekeye experience is broad treating a range of psychiatric disorders including depression, mood disorder, anxiety, panic disorders, sleep disorders, bipolar disorder, post-traumatic stress disorder, obsessive compulsive disorder, premenstrual dysphoric disorder, ADD/ADHD and psychotic disorders. She sees patients aged 11 and older. My approach to patient care is to respect each patient’s values, preferences and expressed needs. Patient will feel safe, accepted, respected, and relatively comfortable. Patient will feel safe, accepted, respected, and relatively comfortable. Dr. Adekeye is a member of the American Academy of Nurse Practitioners, Golden Key International Honor Society, Sigma Theta Tau International Honor Society of Nursing and National Society of Leadership and Success. In her spare time, she enjoys spending time with her family, involving in church events, cooking, traveling and reading.`

    return (
        <div className='About-us'>
          <div className="top-image">
            <h1>Welcome to About Us Page</h1>    
          </div>
    
          <div className="about-container">
            <div className="about-left">
                <div className="about-doc">
                    <h2>About Dr. Oluwatoyin Adekeye</h2>
                    <p><span><img src={DoctorImg} alt="Centre" /></span>{AboutDoc}</p>
                </div><br />
                <div className="about-centre">
                    <h2>About St Mary's Medical Center</h2>
                    <p>Welcome to St. Mary's Medical Center, a comprehensive healthcare platform that is dedicated to providing top-quality medical services to patients in need, including psychiatric telehealth services. Our goal is to help individuals and families achieve optimal health and wellbeing by offering a broad range of psychiatric consultation services that are tailored to their unique needs. <br/>
                    At St. Mary's Medical Center, we understand that mental health is an essential part of overall health, and we are committed to providing our patients with the highest quality psychiatric telehealth services. We offer confidential and convenient online consultations with board-certified psychiatrists to address a wide range of mental health issues, including anxiety, depression, bipolar disorder, PTSD, and more.<br/>
                    Our telehealth services are designed to make mental healthcare accessible to everyone, regardless of their location or mobility issues. Our platform is secure and user-friendly, ensuring that our clients can access our services from the comfort of their homes, without the need to travel to a physical office. Our clients can choose to have their consultations via video or phone, whichever they find most comfortable. <br/><br />
                    At the moment we are particular about the following conditions:</p>
                    <ul>
                      <li>Hyperactive Disorder (ADD/ADHD)</li>
                      <li>Anxiety</li>
                      <li>Autism</li>
                      <li>Developmental Disorder</li>
                      <li>Depression</li>
                      <li>Panic Attack</li>
                      <li>Bipolar Disorder</li>
                      <li>Mood Disorder</li>
                      <li>Borderline Personality Disorder</li>
                      <li>Obsessive-Compulsive Disorder (OCD)</li>
                      <li>Trauma and Posttraumatic Stress Disorder (PTSD)</li>
                      <li>Substance Abuse</li>
                      <li>Panic Disorder</li>
                      <li>Premenstrual Dysphoric Disorder (PMDD)</li>
                      <li>Sleep Disorders</li>
                      <li>Medication Management</li>
                    </ul><br />

                    <p>We understand the importance of privacy and confidentiality, and we take it seriously. Our platform uses end-to-end encryption, and we comply with all relevant laws and regulations to ensure that your information is kept safe and confidential. <br/>
                    Our team of experienced psychiatrists are compassionate and non-judgmental, and they will work with you to develop a personalized treatment plan that meets your unique needs. We believe that everyone deserves access to quality mental healthcare, and we strive to make that a reality. <br/>
                    If you are in need of psychiatric telehealth services or have any questions about our platform, please don't hesitate to contact us. Our team is always here to help and support you in any way we can. Thank you for choosing St. Mary's Medical Center for your mental health needs.</p>
                </div>
                <div className="bottom-images">
                    <TeamSection />
                </div>
            </div>
            <div className="about-right-sidebar"> 
                <Vision />
                <Mission />
                <img src={OurVision} alt="Something" />
            </div>
          </div>
        </div>
      )
    }
  

export default AboutPage