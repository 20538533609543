import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Heartspin from '../heartspin/Heartspin'
import './BlogPost.css'

function BlogDetails({ match }) {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPost(id);
  }, [id]);

  const fetchPost = postId => {
    fetch(`https://blogs.stmarysmedcenter.com/wp-json/wp/v2/posts/${postId}`)
      .then(response => response.json())
      .then(data => setPost(data))
      .catch(error => console.log(error));
  };

  const handleBackHome = () => {
    navigate ('/')
  }


  if (!post) {
    return (
          <div className='heart-spin-container'>
              <Heartspin />
            <h2>Loading . . .</h2>
          </div>
    )
  }

  const date = new Date(post.date);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  const lastUpdated = new Date(post.modified);
  const formattedLastUpdated = `${lastUpdated.getMonth() + 1}/${lastUpdated.getDate()}/${lastUpdated.getFullYear()}`;


  return (
    <div className="blog-details-container">
      <article className="details">
        <h2>{post.title.rendered}</h2>
        <p>Posted by: {post.author} </p>
        <span>
          <p>Posted: {formattedDate}</p>
          {post.modified !== post.date && <p>Last updated: {formattedLastUpdated}</p>}
        </span>
        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div><br />
        <hr />
        <button onClick={handleBackHome}>Back to Home</button>
        </article>
    </div>
  );
}

export default BlogDetails;