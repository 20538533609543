import React, { useState, useEffect } from 'react';
import './BlogSidebar.css'
import '../../App.css'
import Mission from '../mission/Mission'
import Vision from '../mission/Vision'
import Depressed from '../../assets/images/depressed.jpg'


const BlogSidebar = () => {
  return (             
      <div className='recent-posts'>
            <div className="recent-main">
              <img src={Depressed} alt="Depressed Lady" />
            </div>
            <div className="heading1">
                <Mission />
                <Vision />
            </div> 
      </div>    
  );  
};

export default BlogSidebar;

