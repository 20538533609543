import React from 'react'
import './TeamSection.css'
import TeamMember1 from '../../assets/images/team1.webp'
import TeamMember2 from '../../assets/images/team3.jpg'
import TeamMember3 from '../../assets/images/team4.jpg'


const TeamSection = () => {
    
    const AboutTeam = ' '
    return (
    
    <div className='team-container'>
        <h2>Our Team</h2>
        <div className="team-content">
            <div className="team-member">
                <img src={TeamMember1} alt="Team Member" />
                <div className='team-member-content'>
                    <h4>Team Member 1</h4>
                    <article>
                        <span></span><br />
                        <p>{AboutTeam}</p>
                    </article>                
                </div>
            </div>
            <div className="team-member">
                <img src={TeamMember2} alt="Team Member" />
                <div className='team-member-content'>
                    <h4>Team Member 2</h4>
                    <article>
                        <span></span><br />
                        <p>{AboutTeam}</p>
                    </article>                
                </div>
            </div>
            <div className="team-member">
                <img src={TeamMember3} alt="Team Member" />
                <div className='team-member-content'>
                    <h4>Team Member 3</h4>
                    <article>
                        <span></span><br />
                        <p>{AboutTeam}</p>
                    </article>                
                </div>
            </div>
        </div>
    </div>
  )
}

export default TeamSection