import React, { useRef, useState } from 'react';
import './Appointment.css'
import AppointImage from '../../assets/images/dedoc.webp'
import emailjs from '@emailjs/browser';

const BookAppointment = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [gender, setGender] = useState('');
  const [appointmentMsg, setAppointmentMessage] = useState('');
  const form = useRef();

   const handleSubmit = (e) => {
    e.preventDefault();    

    emailjs.sendForm('service_2p2b7h9', 'template_yxmzirs', form.current, 't5qEsy_sU1o_8ZyNe')
      .then((result) => {
        alert('Thank you for Booking an Appointment with us, we will get back to you!')
      }, (error) => {
        alert('Failed to Book an appointment at this time. Plaese, try again later!')
      });
        e.target.reset();
  };
  
  return (
    <div className="main">
        <div className="left-container">
            <img src={AppointImage} alt="doctor" />
        </div>
        <div className='right-container'>
        <h1>Book An Appointment</h1>
        <form ref={form} onSubmit={handleSubmit}>
            <div className='your-name'>
                <label>
                First Name:
                <input
                    name='user_firstname'
                    required
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                </label>
                <br />
                <label>
                Last Name:
                <input
                    name='user_lastname'
                    required
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                </label>
            </div>       
            <br />
            <label>
            Email:
            <input
                name='user_email'
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            </label>
            <br />
            <label>
            Phone:
            <input
                name='user_phone'
                required
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
            </label>
            <br />
            <label>
            Appointment Date:
            <input
                name='user_date'
                type="date"
                value={appointmentDate}
                onChange={(e) => setAppointmentDate(e.target.value)}
            />
            </label>
            <br />
            <label>
            Appointment Time:
            <input
                name='user_aptTime'
                type="time"
                value={appointmentTime}
                onChange={(e) => setAppointmentTime(e.target.value)}
            />
            </label>
            <br />
            <label>
            Gender:
            <select name='user_gender' value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="">Select a gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
            </select>
            </label>
            <br />
            <label>
            Brief Description:
            <textarea
                name='message'
                type="text"
                value={appointmentMsg}
                onChange={(e) => setAppointmentMessage(e.target.value)}
            />
            </label>
            <br />
            <button type="submit">Book Appointment</button>
            
        </form>
        </div>
    </div>
  );
};

export default BookAppointment;

