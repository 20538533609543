import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "./Dachboard.css";
import Profile from "../../assets/images/team1.webp";
import ReadPost from './ReadPost';
// import UpcomingEvents from "../upcoming/UpcomingEvents";



const Sidebar = () => {
  const [menuItems, setMenuItems] = useState([
    { name: "Dashboard", link: "/dashboard" },
    { name: "Create Post", link: "/dashboard/myblogs" },
    { name: "Add Quote", link: "/dashboard/quotes" },
    { name: "Create Events", link: "/dashboard/events" },
    { name: "MailBox", link: "https://app.titan.email/login/"},
    { name: "Settings", link: "/dashboard/settings" },
    { name: "Unassigned", link: "/dashboard/#" },
    { name: "Sign Out", link: "/signup" }
  ]);

  return (
    <div className="sidebar">
      <div className="top-sidebar">
        <span className="image-cont">
          <img src={Profile} alt="Profile" />
        </span>
      </div>
      <ul>
        {menuItems.map(item => (
          <li key={item.name}>
            <Link to={item.link}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

// For the Blogs display on Dashboard

const MyDashboard = () => {

  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await fetch('https://api.jsonbin.io/v3/b/64190e0ec0e7653a058b8a22');
          const data = await response.json();
          setPosts(data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchPosts();
    }, []);

    const handleOpenPost = (post) => {
      setSelectedPost(post);
    };

    return (
      <div className="post-list">
        <h1>All Posts</h1>
      {selectedPost ? (
        <ReadPost post={selectedPost} onClose={() => setSelectedPost(null)} />
      ) : (
        <table>
          <thead>
            <tr>
              <th>Post Id</th>
              <th>Title</th>
              <th>Author</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {posts.map(post => (
              <tr key={post.id}>
                <td>{post.id}</td>
                <td>
                  <div className="td-image">
                    <img src={post.image} alt={post.title} />
                  </div>
                </td>
                <td>{post.title}</td>
                <td>{post.author}</td>
                <td>
                  <button onClick={() => handleOpenPost(post)}>Open Post</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};



const CreateBlog = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState();

  const handleCreateBlog = (e) => {
    e.preventDefault();
    
    const post = {title, content, image, author}

    fetch('https://api.jsonbin.io/v3/b/64190e0ec0e7653a058b8a22/', {
      
      method: 'POST',
      headers:{"content-Type":"application/json"},
      body: JSON.stringify(post)
    }).then(() =>{
      console.log(post);
      alert("New Blog Added Successfully");
      navigate('/dashboard')
    });

  } 

  
  return (
    <div className="main-content text-3xl text-center font-normal">
      
      <div className="form-container flex flex-col">
        <h1 className="text-2xl font-normal underline">Create a New Blog post</h1>
        <form onSubmit={handleCreateBlog}>
          <div className="blog-title">
            <label htmlFor="title">Title:</label>
            <input 
              type="text"
              required
              placeholder="Type in the Blog Title here"
              onChange={e => setTitle(e.target.value)}

              />
          </div>
          <div className="blog-author">
            <label htmlFor="author">Author:</label>
            <input 
              type="text"
              required
              placeholder="Type in the Author here"
              onChange={e => setAuthor(e.target.value)}
              
              />
          </div>
          <div className="blog-content">
            <label htmlFor="content">Content</label>
            <textarea 
              cols="20" 
              rows="5"
              required
              onChange={e=>setContent(e.target.value)}
              >
              </textarea>
          </div>
          <div className="blog-image">
            <label htmlFor="image">Upload image:</label>
            <input 
              type="file"
              placeholder="Upload image if available"
              onChange={setImage}
              />
              {/* <img src={imageUrl} /> */}
          </div>
          {!isLoading && <button onClick={handleCreateBlog}>Create Post</button>}
          {isLoading && <button disabled>Creating Post, Please wait...</button>}
        </form>
      </div>
    </div>
  );
}


const MyQuotes = () => {
  const [isLoading, setIsLoading] = useState(false);
 
  const handleCreateBlog = (e) => {
    e.preventDefault();
    setIsLoading(true);
  } 

  
  return (
    <div className="main-content text-3xl text-center font-normal">
      
      <div className="form-container flex flex-col">        
        <h1>Add a New Quote to your Page</h1>
        <form>
          <div className="quote-author">
            <label htmlFor="author"><i className="el el-person">Author</i>:</label>
            <input 
              type="text"
              required
              placeholder="Type in the Author here"
              
              />
          </div>
          <div className="quote-content">
            <label htmlFor="quote">Quote</label>
            <textarea 
              cols="20" 
              rows="3"
              required
              >
              </textarea>
          </div>
          {!isLoading && <button onClick={handleCreateBlog}>Add Quote</button>}
          {isLoading && <button disabled>Adding Quote, Please wait...</button>}
        </form>
      </div>
    </div>
  );
}

const MyEvents = () => {
  const [isLoading, setIsLoading] = useState(false);  const [hidden, setHidden] = useState(false);
 
  const handleCreateBlog = (e) => {
    e.preventDefault();
    setIsLoading(true);
  } 
  
  return (
    <div className="main-content text-3xl text-center font-normal">
      
      <div className="form-container flex flex-col">
        <h1>Post Upcoming Event</h1>
        <form>
          <div className="event-title">
            <div className="events-on-off">
              <p>Turn Event display On/Off: </p>
            </div>
            <label htmlFor="title">Event Heading:</label>
            <input 
              type="text"
              required
              placeholder="Type in the Blog Title here"

              />
          </div>
          <div className="blog-author">
            <label htmlFor="date">Date:</label>
            <input 
              type="date"
              required
              placeholder="Type in the Author here"
              
              />
          </div>
          <div className="blog-content">
            <label htmlFor="content">Description:</label>
            <textarea 
              cols="20" 
              rows="5"
              required
              >
              </textarea>
          </div>
          {!isLoading && <button onClick={handleCreateBlog}>Create an Event</button>}
          {isLoading && <button disabled>Creating Event, Please wait...</button>}
        </form>
      </div>
    </div>
  );
}

const Settings = () => {
  return (
    <div className="main-content text-3xl text-center font-normal">
      <h1>Settings</h1>
      <p>This is the settings page.</p>
    </div>
  );
}

const Greetings = () =>{
  const time = new Date();
  const hours = time.getHours();
  if(hours < 12){
    return "Good morning!"
  }else if(hours < 17 ){
    return "Good afternoon!"
  }else if(hours < 24){ 
    return "Good evening"
  }
}

const Dashboard = () => {
  
  return (
    <div className="dashboard-container">
      <h1 className="text-2xl text-center font-light top-title py-4 mb-5">
        <Greetings /> Welcome to the Dashboard.
      </h1>
      <div className="dash">
        <div className="dash-left">
            <Sidebar />
        </div>        
        <div className="dash-right">
            <Routes>
                <Route path="/" element={<MyDashboard/>} />
                <Route path="/myblogs" element={<CreateBlog/>} />
                <Route path="/quotes" element={<MyQuotes/>} />
                <Route path="/events" element={<MyEvents/>} />
                <Route path="/settings" element={<Settings/>} />
                {/* <Route path="/postdetails" element={<DashboardPost />} /> */}
                <Route path="/mailbox" element="https://app.titan.email/login/" target="_blank"/>
            </Routes>
        </div>       
      </div>
    </div>
  );
}

export default Dashboard
