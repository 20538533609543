import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

const ServiceBlog=() => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    fetch('https://blogs.stmarysmedcenter.com/wp-json/wp/v2/posts?_embed&per_page=8')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.log(error));
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '2rem' }}>
      {posts.map(post => (
        <Link to={`/post/${post.id}`}>
            <div key={post.id} style={{ flex: '0 0 250px', width: '250px', height: '300px', margin: '10px' }}>
              {post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url && (
                <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              )}
              <h2>{post.title.rendered}</h2>
              <p>Posted: {new Date(post.date).toLocaleDateString()}</p>
            </div>
        </Link>
      ))}
    </div>
  );
}

export default ServiceBlog;