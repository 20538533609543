import React, { useState, useEffect } from 'react';
import './Quotes.css'

function Quotes() {
  const [quotes, setQuotes] = useState([
      { 
        "quote": "Your mental health is a priority. Your happiness is essential. Your    self-care is a necessity." ,
        "author": "- Unknown"
      },
      {
        "quote": "Mental health is not a destination, but a process. It's about how you drive, not where you're going." ,
        "author": "- Noam Shpancer"
      },
      {
        "quote": "It's okay to not be okay. It's okay to ask for help. It's okay to take a break." ,
        "author": "- Unknown"
      },
      {
         "quote": "Mental health is not a joke. It is not a weakness. It is a strength.", 
         "author": "- Unknown"
      },
      {
        "quote": "The strongest people are not those who show strength in front of us, but those who win battles we know nothing about.", 
        "author": "- Unknown"
      },
      {
        "quote": "Taking care of yourself is part of taking care of others.",
        "author": "- Unknown"
      },
      {
        "quote": "You don't have to be positive all the time. It's okay to feel sad, angry, annoyed, frustrated, scared, or anxious. Having feelings doesn't make you a negative person. It makes you human.",
        "author": "- Lori Deschene"
      },
      {
        "quote": "Mental health is not a luxury. It's a necessity.", 
        "author": "- Unknown"
      },
      {
        "quote": "Sometimes the people around you won't understand your journey. They don't need to, it's not for them.", 
        "author": "- Joubert Botha"
      },
      {
        "quote": "The only way out of the labyrinth of suffering is to forgive.", 
        "author": "- John Green"
      },
      {
        "quote": "You, yourself, as much as anybody in the entire universe, deserve your love and affection.", 
        "author": "- Buddha"
      },
      {
        "quote": "It's not just about being happy. It's about learning to overcome your fear and pain, to embrace the struggle, and to create a meaningful life." ,
        "author": "- Susan David"
      },
      {
        "quote": "Healing takes time, and asking for help is a courageous step." ,
        "author": "- Mariska Hargitay"
      },
      {
        "quote": "The only way to do great work is to love what you do." ,
        "author": "- Steve Jobs"
      },
      {
        "quote": "It's okay to be a glowstick. Sometimes we need to break before we shine." ,
        "author": "- Unknown"
      },
      {
        "quote": "Mental health needs a great deal of attention. It's the final taboo and it needs to be faced and dealt with." ,
        "author": "- Adam Ant"
      },
      {
        "quote": "Don't wait for things to get better. Life will always be complicated. Learn to be happy right now, otherwise, you'll run out of time." ,
        "author": "- Unknown"
      },
      {
        "quote": "Self-care is not selfish. You cannot serve from an empty vessel.", 
        "author": "- Eleanor Brown"
      },
      {
        "quote": "We are all broken, that's how the light gets in.", 
        "author": "- Ernest Hemingway"
      },
      {
        "quote": "Don't let your struggle become your identity.", 
        "author": "- Unknown"
}]);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuoteIndex(currentQuoteIndex => (currentQuoteIndex + 1) % quotes.length);
    }, 7000);
    return () => clearInterval(timer);
  }, [quotes.length]);

  return (
    <div className='quote-main'>
      <h1>Random Quotes</h1>
      {quotes.length > 0 &&
        <blockquote>
          <p>{quotes[currentQuoteIndex].quote}</p>
          <cite>{quotes[currentQuoteIndex].author}</cite>
        </blockquote>
      }
    </div>
  );
}

export default Quotes;
