import React from 'react'
import './App.css'
import Navbar from '../src/components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Copyright from './components/footer/Copyright'
import { RoutesConfig } from './core/routes/Routes'




const App = () => {
  return (
    <div className='App'>
      <header className='top-navbar'>
          <Navbar />                  
      </header>
      <RoutesConfig />            
      <footer className='footer-section'>
        <Footer />
        <Copyright />
      </footer>
    </div>
  );
}

export default App
