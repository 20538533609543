import React from 'react'
import './Footer.css'
import email from '../../assets/social/email.png'
import facebook from '../../assets/social/facebook.png'
import instagram from '../../assets/social/instagram icon.png'
import linkedin from '../../assets/social/linkedin.png'
import location from '../../assets/social/location.png'
import phone from '../../assets/social/phone.png'
import Logo from '../mylogo/Logo'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-top">
            <div className="left-top-footer">
                <div className="logo">
                    <Logo />
                </div>
                <div className="footer-top-left">
                    <h3>St. Mary's Medical Center</h3>
                    <p>We are a Telehealth Medical Organization, committed to attending to your health questions. It is our goal to make the world a healthy place.</p>
                </div>
            </div>
            <div className='right-top-footer'>
                <div className="footer-top-mid">
                    <h3>Office Hours</h3>
                    <p>We are open 9:00am - 4:30pm: <br /> Monday - Friday</p>
                </div>
                <div className="footer-top-right">
                    <p><img src={phone} alt="phone icon" />  <a href="tel:+2405478986">240-547-8986</a><br /></p>
                    <p ><img src={email} alt="email icon"/> <a href="mailto:st.marysmedicalcenter@outlook.com" target='_blank' rel="noopener noreferrer">st.marysmedicalcenter@outlook.com</a></p>
                    <div className="social-icons">
                        <img src={linkedin} alt="linkedin" href="mailto:st.marysmedicalcenter@outlook.com" target='_blank' rel="noopener noreferrer" /> 
                        <img src={instagram} alt="instagram icon" />
                        <img src={location} alt="linkedin" /> 
                        <img src={facebook} alt="instagram icon" />
                    </div>               
                </div>
            </div>
        </div>
        
      
    </div>
  )
}

export default Footer
