import React from 'react'
import Header from '../components/header/Header'
import Section from '../components/section/Section'
import Quote from '../components/quotes/Quotes'
import ServiceBlog from '../components/serviceblog/ServiceBlog'
import UpcomingEvents from '../components/upcoming/UpcomingEvents'
import TopSectionContent from '../components/sectionContent/TopSectionContent'

const Home = () => {
  return (
    <div>
      <Header />
      <Section />
      <TopSectionContent />
      <ServiceBlog />
      <UpcomingEvents />
      <Quote />
    </div>
  )
}

export default Home
