import React from 'react'
import './Footer.css'

const Copyright = () => {
  const time = new Date();
  const year = time.getFullYear();

  return (
        <div className="footer-bottom">
            <span><p>Copyright &copy; {year}.&nbsp;</p><h4>St. Mary's Medical Center.</h4></span>   
        </div>
  )
}

export default Copyright