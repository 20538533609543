import React from 'react'
import './MobileMenu.css'
import { Link } from 'react-router-dom';
import list from '../../assets/images/list.png'

const MobileMenu = () => {
    const onMenuClick = ()=>{
      const menuList = document.querySelector('.mobile-nav-links')
      if (menuList.style.display === "flex") {
        menuList.style.display = "none";
      } else {
        menuList.style.display = "flex";
      }
    }

  return (
    <div className='mobile-menu'>
      <img src={list} alt="menu" onClick={onMenuClick}/>
      <div className='mobile-nav-links'>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/blog">Blog</Link>
        <Link to="https://app.titan.email/login/" target='_blank'>Webmail</Link>
        <Link to="https://blogs.stmarysmedcenter.com/wp-login.php" target='_blank'>Login</Link>
        <Link to="/appointment"><span>Book Appointment</span></Link>
      </div>
    </div>
    
  )
}

export default MobileMenu
