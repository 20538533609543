import React from 'react';
import './Navbar.css'
import { Link } from 'react-router-dom';
import MobileMenu from '../mobile/MobileMenu';
import Logo from '../mylogo/Logo';


const Navbar = () => {

  return (
    <nav className='top-nav'>
        <div className="logo-div">
            <Logo />
        </div>
        <div className="menu-link-container">
          <div className='nav-links'>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/blog">Blog</Link>
          <Link to={"https://blogs.stmarysmedcenter.com/wp-login.php"} target="_blank">Login</Link>
          <Link to={"https://app.titan.email/login/"} target="_blank">Webmail</Link>
          <Link to="/appointment"><span>Book Appointment</span></Link>
          </div>
          <div className="mobile-menu">
          <MobileMenu />
          </div>
        </div>
    </nav>
  )
}

export default Navbar