// ReadPost.js
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './Dachboard.css'
import useFetch from '../../useFetch'


    
const ReadPost = ({ post, onClose }) => {
    const { data } = useFetch('https://api.jsonbin.io/v3/b/64190e0ec0e7653a058b8a22/');
    const navigate = useNavigate();

    const handleDeletePost = () => {
        fetch('https://api.jsonbin.io/v3/b/64190e0ec0e7653a058b8a22/' + post.id, {
          method: 'DELETE'
        }).then (() =>{
          navigate('/dashboard')
        })
      };  

  return (
    <div className='readpost-container'>
      <div className="headers-container">
        <h2>{post.title}</h2>
        <p>By {post.author}</p>
      </div>
      <div className="readpost-image">
        <img src={post.image} alt={post.title} />
      </div>
      <p>{post.content}</p>
      <div className="readpost-buttons">
        <button onClick={onClose}>Close</button>
        <button onClick={handleDeletePost}>Delete</button>
      </div>
    </div>
  );
};

export default ReadPost;
